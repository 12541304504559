import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import Axios from "axios";

let Sleep = ({ uid, darkmode, isMobile }) => {
  let [days, setDays] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const isMounted = useRef(false);

  useEffect(() => {
    getUser();
  }, []);

  let getUser = async () => {
    console.log("getUser", uid);
    var options = {
      method: "GET",
      url: "https://quotron.co/user/" + uid,

      headers: {
        "Content-Type": "application/json",
      },
    };
    let data = await Axios.request(options)
      .then((res) => {
        if (res.data?.length > 0) {
          return res.data[0];
        }
      })
      .catch((err) => {
        console.log("user err", err);
        isMounted.current = true;
      });
    if (data.sleep) {
      //console.log("data.sleep", data.sleep);
      setDays(
        data.sleep?.days || [false, false, false, false, false, false, false]
      );
      setHours(data.sleep?.off || hoursArr[9]);
      setWake(data.sleep?.on || wakeArr[5]);
      setEoff(data.sleep?.eOff);
      setEon(data.sleep?.eOn);
      isMounted.current = true;
    }
  };

  let hoursArr = [
    { value: 13, label: "1 PM" },
    { value: 14, label: "2 PM" },
    { value: 15, label: "3 PM" },
    { value: 16, label: "4 PM" },
    { value: 17, label: "5 PM" },
    { value: 18, label: "6 PM" },
    { value: 19, label: "7 PM" },
    { value: 20, label: "8 PM" },
    { value: 21, label: "9 PM" },
    { value: 22, label: "10 PM" },
    { value: 23, label: "11 PM" },
    { value: 0, label: "12 AM" },
    { value: 1, label: "1 AM" },
    { value: 2, label: "2 AM" },
    { value: 3, label: "3 AM" },
    { value: 4, label: "4 AM" },
    { value: 5, label: "5 AM" },
    { value: 6, label: "6 AM" },
    { value: 7, label: "7 AM" },
    { value: 8, label: "8 AM" },
    { value: 9, label: "9 AM" },
    { value: 10, label: "10 AM" },
    { value: 11, label: "11 AM" },
    { value: 12, label: "12 PM" },
  ];
  let wakeArr = [
    { value: 1, label: "1 AM" },
    { value: 2, label: "2 AM" },
    { value: 3, label: "3 AM" },
    { value: 4, label: "4 AM" },
    { value: 5, label: "5 AM" },
    { value: 6, label: "6 AM" },
    { value: 7, label: "7 AM" },
    { value: 8, label: "8 AM" },
    { value: 9, label: "9 AM" },
    { value: 10, label: "10 AM" },
    { value: 11, label: "11 AM" },
    { value: 12, label: "12 PM" },
    { value: 13, label: "1 PM" },
    { value: 14, label: "2 PM" },
    { value: 15, label: "3 PM" },
    { value: 16, label: "4 PM" },
    { value: 17, label: "5 PM" },
    { value: 18, label: "6 PM" },
    { value: 19, label: "7 PM" },
    { value: 20, label: "8 PM" },
    { value: 21, label: "9 PM" },
    { value: 22, label: "10 PM" },
    { value: 23, label: "11 PM" },
    { value: 0, label: "12 AM" },
  ];

  let pmArr = [
    { value: 1, label: "AM" },
    { value: 2, label: "PM" },
  ];
  let [hours, setHours] = useState(hoursArr[9]);
  let [wake, setWake] = useState(wakeArr[5]);
  let [sleepPm, setSleepPm] = useState(pmArr[1]);
  let [wakePm, setWakePm] = useState(pmArr[0]);
  let [eOff, setEoff] = useState(false);
  let [eOn, setEon] = useState(false);
  let [msg, setMsg] = useState(false);

  let darkColor = darkmode ? "#272727" : "#777";

  let toggle = (num) => {
    setDays((days) => {
      let newDays = [...days];
      newDays[num] = !newDays[num];
      update(newDays);
      return newDays;
    });
  };

  /*
  useEffect(() => {
    update();
  }, [hours, wake]);
  */

  useEffect(() => {
    if (isMounted.current) {
      update();
    }
  }, [hours, wake, eOff, eOn]);

  let update = async (newDays = days) => {
    var options = {
      method: "POST",
      //url: "http://localhost:80/sleep",
      url: "https://quotron.co/sleep",
      data: {
        uid: uid,
        days: newDays,
        off: hours,
        on: wake,
        eOff: eOff,
        eOn: eOn,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    console.log("update", options.data);
    Axios.request(options)
      .then((res) => {
        console.log("sleep res", res);
      })
      .catch((err) => {
        console.log("sleep err", err);
      });
  };

  //console.log("days", days);

  let sleepNow = () => {
    if (eOn) {
      setEon(false);
    } else {
      let timeStamp = Date.now();
      setEon(timeStamp);
    }
  };

  return (
    <div style={{ minWidth: isMobile ? 360 : 360 }}>
      <div
        className={"graphTextName"}
        style={{
          fontSize: 16,
          alignSelf: "flex-start",
          color: darkmode ? "#ccc" : "#444",
          marginBottom: 6,
          marginTop: 6,
        }}
      >
        Sleep Mode:
      </div>
      <div
        className={
          darkmode ? "prefBoxDark settingsText" : "prefBox settingsText"
        }
        style={{ alignSelf: "stretch", alignItems: "flex-start" }}
      >
        <div className={"flexRow"} style={{ alignItems: "center" }}>
          <div style={{ marginRight: 10 }}>Sleep at: </div>
          <Select
            options={hoursArr}
            styles={{
              menuList: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? darkColor : darkColor,
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#444" : darkColor,
                color: "#fff",
              }),
              valueContainer: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#444" : darkColor,
              }),
              singleValue: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#444" : darkColor,
                color: darkmode ? "#bbb" : "#fff",
              }),
              control: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: darkColor,
                color: darkmode ? "#ccc" : "#fff",
                borderColor: "#777",
              }),
              dropdownIndicator: (baseStyles, state) => ({
                ...baseStyles,
                color: "#999",
              }),
            }}
            onChange={(value) => setHours(value)}
            value={hours}
          />
          {false && (
            <div style={{ marginLeft: 4 }}>
              <Select
                options={pmArr}
                styles={{
                  menuList: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isFocused ? darkColor : darkColor,
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isFocused ? "#444" : darkColor,
                    color: "#fff",
                  }),
                  valueContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isFocused ? "#444" : darkColor,
                  }),
                  singleValue: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isFocused ? "#444" : darkColor,
                    color: darkmode ? "#bbb" : "#fff",
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: darkColor,
                    color: darkmode ? "#ccc" : "#fff",
                    borderColor: "#777",
                  }),
                  dropdownIndicator: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#999",
                  }),
                }}
                onChange={(value) => setSleepPm(value)}
                value={sleepPm}
              />
            </div>
          )}
        </div>
        <div
          className={"flexRow"}
          style={{ alignItems: "center", marginTop: 6 }}
        >
          <div style={{ marginRight: 9 }}>Wake at: </div>
          <Select
            options={wakeArr}
            styles={{
              menuList: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? darkColor : darkColor,
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#444" : darkColor,
                color: "#fff",
              }),
              valueContainer: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#444" : darkColor,
              }),
              singleValue: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#444" : darkColor,
                color: darkmode ? "#bbb" : "#fff",
              }),
              control: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: darkColor,
                color: darkmode ? "#ccc" : "#fff",
                borderColor: "#777",
              }),
              dropdownIndicator: (baseStyles, state) => ({
                ...baseStyles,
                color: "#999",
              }),
            }}
            onChange={(value) => setWake(value)}
            value={wake}
          />
          {false && (
            <div style={{ marginLeft: 4 }}>
              <Select
                options={pmArr}
                styles={{
                  menuList: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isFocused ? darkColor : darkColor,
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isFocused ? "#444" : darkColor,
                    color: "#fff",
                  }),
                  valueContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isFocused ? "#444" : darkColor,
                  }),
                  singleValue: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isFocused ? "#444" : darkColor,
                    color: darkmode ? "#bbb" : "#fff",
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: darkColor,
                    color: darkmode ? "#ccc" : "#fff",
                    borderColor: "#777",
                  }),
                  dropdownIndicator: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#999",
                  }),
                }}
                onChange={(value) => setWakePm(value)}
                value={wakePm}
              />
            </div>
          )}
        </div>

        <div
          className={"flexRow"}
          style={{ alignItems: "center", marginTop: 13 }}
        >
          <div style={{ marginRight: 26.5 }}>Days of the week: </div>
        </div>
        <div className={"flexRow"} style={{ marginTop: 10, marginBottom: 10 }}>
          <div
            onClick={() => toggle(0)}
            style={
              days[0]
                ? darkmode
                  ? local.nightActive
                  : local.dayActive
                : local.day
            }
          >{`Su`}</div>
          <div
            onClick={() => toggle(1)}
            style={
              days[1]
                ? darkmode
                  ? local.nightActive
                  : local.dayActive
                : local.day
            }
          >{`M`}</div>
          <div
            onClick={() => toggle(2)}
            style={
              days[2]
                ? darkmode
                  ? local.nightActive
                  : local.dayActive
                : local.day
            }
          >{`T`}</div>
          <div
            onClick={() => toggle(3)}
            style={
              days[3]
                ? darkmode
                  ? local.nightActive
                  : local.dayActive
                : local.day
            }
          >{`W`}</div>
          <div
            onClick={() => toggle(4)}
            style={
              days[4]
                ? darkmode
                  ? local.nightActive
                  : local.dayActive
                : local.day
            }
          >{`Th`}</div>
          <div
            onClick={() => toggle(5)}
            style={
              days[5]
                ? darkmode
                  ? local.nightActive
                  : local.dayActive
                : local.day
            }
          >{`F`}</div>
          <div
            onClick={() => toggle(6)}
            style={
              days[6]
                ? darkmode
                  ? local.nightActive
                  : local.dayActive
                : local.day
            }
          >{`S`}</div>
        </div>
        <div
          style={{
            width: 272,
            border: "0px solid #888",
            borderBottomWidth: 1,
            marginTop: 6,
            marginBottom: 14,
          }}
        />
        <div className={"flexRow"} style={{ marginBottom: 10 }}>
          <div
            onClick={() => {
              if (!eOn && eOff) {
                setEoff(false);
              }
              sleepNow();
              setMsg(true);
            }}
            className={"flexRow"}
            style={{ alignItems: "center" }}
          >
            <div
              style={
                eOn
                  ? darkmode
                    ? local.nightActiveE
                    : local.dayActiveE
                  : local.dayE
              }
            >{`Sleep Now`}</div>
          </div>
          <div
            onClick={() => {
              if (!eOff && eOn) {
                setEon(false);
              }
              setEoff(!eOff);
              setMsg(true);
            }}
            className={"flexRow"}
            style={{ alignItems: "center" }}
          >
            <div
              style={
                eOff
                  ? darkmode
                    ? local.nightActiveE
                    : local.dayActiveE
                  : local.dayE
              }
            >{`Wake Now`}</div>
          </div>
        </div>
        {msg && (
          <>
            <div
              style={{ marginBottom: 10, width: 300 }}
            >{`*Box will update aprx ~1 min`}</div>
          </>
        )}
      </div>
    </div>
  );
};

let local = {
  day: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 30,
    width: 30,
    borderRadius: 2,
    border: "1px solid #888",
    marginRight: 10,
    cursor: "pointer",
  },
  dayActive: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 30,
    width: 30,
    borderRadius: 2,
    border: "1px solid #888",
    marginRight: 10,
    color: "#FFF",
    backgroundColor: "#888",
    cursor: "pointer",
  },
  nightActive: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 30,
    width: 30,
    borderRadius: 2,
    border: "1px solid #888",
    marginRight: 10,
    color: "#000",
    backgroundColor: "#768dcf",
    cursor: "pointer",
  },

  dayE: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 30,
    width: 100,
    borderRadius: 2,
    border: "1px solid #888",
    marginRight: 10,
    cursor: "pointer",
  },
  dayActiveE: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 30,
    width: 100,
    borderRadius: 2,
    border: "1px solid #888",
    marginRight: 10,
    color: "#FFF",
    backgroundColor: "#888",
    cursor: "pointer",
  },
  nightActiveE: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 30,
    width: 100,
    borderRadius: 2,
    border: "1px solid #888",
    marginRight: 10,
    color: "#000",
    backgroundColor: "#768dcf",
    cursor: "pointer",
  },
};

export default Sleep;
