import React, { useState, useEffect } from "react";
import Axios from "axios";
import ReactJson from "react-json-view";
import "react-dropdown/style.css";
import Select from "react-select";

let Ping = ({ activeDeviceRow, uid, isMobile, userDevices }) => {
  let [data, setData] = useState({});
  let [showAdded, setShowAdded] = useState(false);

  useEffect(() => {
    req();
  }, [activeDeviceRow]);

  console.log("userDevices", userDevices);
  let thisBox = userDevices.find((device) => device?.id === activeDeviceRow);
  let isAccount = thisBox?.account;
  console.log("thisBox", thisBox, isAccount);

  let panelArr = [
    { value: 1, label: "1x1" },
    { value: 2, label: "1x3" },
    { value: 3, label: "1x4" },
  ];

  let skuArr = [
    { value: 1, label: "stocks" },
    { value: 2, label: "crypto" },
    { value: 3, label: "sports" },
  ];

  useEffect(() => {
    console.log("thisBox", thisBox, isAccount);
    if (thisBox?.sku) {
      let panels = panelArr.find((panel) => panel.label === thisBox.sku?.panels);
      setPanels(panels);
      let sku = skuArr.find((sku) => sku.label === thisBox.sku?.type);
      setSku(sku);
    }
  }, [userDevices]);

  let req = async () => {
    if (!activeDeviceRow) return;

    console.log("req", activeDeviceRow);

    /*
    Axios.get("https://quotron.co/psudoBox/" + activeDeviceRow)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log("ping err", err);
      });
    */
  };

  let darkmode = false;
  let darkColor = darkmode ? "#272727" : "#777";

  let [panels, setPanels] = useState(null);
  let [sku, setSku] = useState(null);

  let saveSku = async () => {
    var options = {
      method: "POST",
      url: "https://quotron.co/admin/saveSku",
      data: {
        boxId: activeDeviceRow,
        uid,
        type: sku.label,
        panels: panels.label,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };

    console.log("saveSku", sku.label, panels.label);

    try {
      let res = await Axios.request(options);
      console.log("saveSku res", res);
      setShowAdded(true);
      setTimeout(() => {
        setShowAdded(false);
      }, 5000);
    } catch (err) {
      console.log("err", err);
    }
  };

  if (!activeDeviceRow) return null;
  return (
    <>
      <div className={"settingsTitle"} style={{ marginTop: 20 }}>
        sku:
      </div>
      <div
        style={{
          border: "1px solid #777",
          paddingLeft: 6,
          paddingBottom: 20,
        }}
      >
        <div className={"settingsTitle"}>device id: {activeDeviceRow}</div>

        <div className={"flexRow"} style={{ marginTop: 10 }}>
          <Select
            options={panelArr}
            styles={{
              menuList: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? darkColor : darkColor,
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#444" : darkColor,
                color: "#fff",
              }),
              valueContainer: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#444" : darkColor,
              }),
              singleValue: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#444" : darkColor,
                color: darkmode ? "#bbb" : "#fff",
              }),
              control: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: darkColor,
                color: darkmode ? "#ccc" : "#fff",
                borderColor: "#777",
              }),
              dropdownIndicator: (baseStyles, state) => ({
                ...baseStyles,
                color: "#999",
              }),
              placeholder: (baseStyles, state) => ({
                ...baseStyles,
                color: "#fff",
              }),
            }}
            onChange={(value) => setPanels(value)}
            value={panels}
            placeholder={"panels"}
          />
          <div style={{ width: 10 }}></div>
          <Select
            options={skuArr}
            styles={{
              menuList: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? darkColor : darkColor,
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#444" : darkColor,
                color: "#fff",
              }),
              valueContainer: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#444" : darkColor,
              }),
              singleValue: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#444" : darkColor,
                color: darkmode ? "#bbb" : "#fff",
              }),
              control: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: darkColor,
                color: darkmode ? "#ccc" : "#fff",
                borderColor: "#777",
              }),
              dropdownIndicator: (baseStyles, state) => ({
                ...baseStyles,
                color: "#999",
              }),
              placeholder: (baseStyles, state) => ({
                ...baseStyles,
                color: "#fff",
              }),
            }}
            onChange={(value) => setSku(value)}
            value={sku}
            placeholder={"sku"}
          />
          <div style={{ width: 10 }}></div>
          {!isAccount ? (
            <div onClick={() => saveSku()} style={local.button}>
              save
            </div>
          ) : (
            <div style={local.buttonNo}>has account</div>
          )}
          {showAdded && <div style={local.addedText}>added</div>}
        </div>
      </div>
    </>
  );
};

let local = {
  button: {
    border: "1px solid #ccc",
    width: 100,
    padding: 6,
    backgroundColor: "#999",
    color: "#fff",
    cursor: "pointer",
  },
  buttonNo: {
    border: "1px solid #ccc",
    width: 100,
    padding: 6,
    backgroundColor: "#FFF",
    color: "#000",
    cursor: "pointer",
  },
  addedText: {
    marginLeft: 10,
    color: "#888",
  },
};
export default Ping;
