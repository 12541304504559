import React from "react";

function PrefMenu(props) {
  let { darkmode, isAdmin, isTrail } = props;

  let activeMenu = darkmode ? "prefMenuItemBlue" : "prefMenuItemDark";
  let inainactiveMenu = darkmode ? "prefMenuItemLight" : "prefMenuItem";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignSelf: "stretch",
        marginTop: props.isMobile ? 0 : -10,
      }}
    >
      <div className={"prefMenuUpper"}>
        <div
          className={
            props.activePrefUpper == "finance" ? activeMenu : inainactiveMenu
          }
          onClick={() => props.togglePrefUpper("finance")}
        >
          finance
        </div>
        <div
          className={
            props.activePrefUpper == "sports" ? activeMenu : inainactiveMenu
          }
          onClick={() => props.togglePrefUpper("sports")}
        >
          sports
        </div>
        {isAdmin && (
          <div
            className={
              props.activePrefUpper == "news" ? activeMenu : inainactiveMenu
            }
            onClick={() => props.togglePrefUpper("news")}
          >
            news
          </div>
        )}
        <div
          className={
            props.activePrefUpper == "weather" ? activeMenu : inainactiveMenu
          }
          onClick={() => props.togglePrefUpper("weather")}
        >
          weather
        </div>

        {isAdmin && false && (
          <div
            className={
              props.activePrefUpper == "custom" ? activeMenu : inainactiveMenu
            }
            onClick={() => props.togglePrefUpper("custom")}
          >
            custom
          </div>
        )}
        <div
          className={
            props.activePrefUpper == "settings" ? activeMenu : inainactiveMenu
          }
          onClick={() => props.togglePrefUpper("settings")}
        >
          <div
            className={"fa fa-cog"}
            style={{
              marginTop: 1,
              marginRight: 1,
              opacity: 0.65,
              paddingLeft: 3,
              paddingRight: 2,
              fontSize: 19,
            }}
          ></div>
        </div>
      </div>
      {props.activePrefUpper == "finance" && props.activePref != "admin" && (
        <div className={"prefMenu"}>
          <div
            className={
              props.activePref == "stocks" ? activeMenu : inainactiveMenu
            }
            onClick={() => props.togglePref("stocks")}
          >
            stocks
          </div>
          <div
            className={
              props.activePref == "crypto" ? activeMenu : inainactiveMenu
            }
            onClick={() => props.togglePref("crypto")}
          >
            crypto
          </div>
          <div
            className={
              props.activePref == "markets" ? activeMenu : inainactiveMenu
            }
            onClick={() => props.togglePref("markets")}
          >
            markets
          </div>
          <div
            className={
              props.activePref == "forex" ? activeMenu : inainactiveMenu
            }
            onClick={() => props.togglePref("forex")}
          >
            forex
          </div>
          <div
            className={
              props.activePref == "comms" ? activeMenu : inainactiveMenu
            }
            onClick={() => props.togglePref("comms")}
          >
            comm.
          </div>
          {false && (
            <div
              className={
                props.activePref == "portfolio" ? activeMenu : inainactiveMenu
              }
              onClick={() => props.togglePref("portfolio")}
            >
              portfolio
            </div>
          )}
        </div>
      )}
      {props.activePrefUpper == "sports" && props.activePref != "admin" && (
        <div className={"prefMenu"}>
          <div
            className={props.activePref == "mlb" ? activeMenu : inainactiveMenu}
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              fontSize: 17,
            }}
            onClick={() => props.togglePref("mlb")}
          >
            MLB
          </div>
          <div
            className={props.activePref == "nfl" ? activeMenu : inainactiveMenu}
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              fontSize: 17,
            }}
            onClick={() => props.togglePref("nfl")}
          >
            NFL
          </div>
          <div
            className={
              props.activePref == "ncaaF" ||
              props.activePref == "ncaaB" ||
              props.activePref == "ncaaH"
                ? activeMenu
                : inainactiveMenu
            }
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              fontSize: 17,
            }}
            onClick={() => props.togglePref("ncaaF")}
          >
            NCAA
          </div>

          <div
            className={props.activePref == "nhl" ? activeMenu : inainactiveMenu}
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              fontSize: 17,
              opacity: 0.3,
            }}
            onClick={() => props.togglePref("nhl")}
          >
            NHL
          </div>

          <div
            className={props.activePref == "nba" ? activeMenu : inainactiveMenu}
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              fontSize: 17,
              opacity: 0.3,
            }}
            onClick={() => props.togglePref("nba")}
          >
            NBA
          </div>
        </div>
      )}
    </div>
  );
}

export default PrefMenu;
