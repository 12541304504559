import React, { Component } from "react";
import { getDatabase, ref, onValue, child, get, set } from "firebase/database";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import { initializeApp, getApps, getApp } from "firebase/app";
import Axios from "axios";
import eyePassImg from "../imgs/eyePass.png";
import eyePassDarkImg from "../imgs/eyePassDark.png";

class Auth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      registerEmail: "",
      registerPassword: "",
      loginEmail: "",
      loginPassword: "",
      registerEmailError: false,
      registerPasswordError: false,
      resentPassword: false,
      validDeviceID: false,
      deviceID: props.newDevice || "",
      deviceIDError: false,
      toggleAuth: props.step || false,
      quantity: "",
      quantityError: false,
      authActiveStock: 0,
      preferences: {
        upDown: "value",
      },
      stockData: null,
      phoneInput: "",
      phoneError: "",
      showCode: false,
      confirmationResult: null,
      eyePass: false,
    };
  }

  componentDidMount() {
    let { newDevice } = this.props;
    if (newDevice) {
      this.deviceSubmit(newDevice);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.step != this.props.step) {
      this.setState({ toggleAuth: this.props.step });
    }
  }

  resendPassword = () => {
    const auth = getAuth();
    let { loginEmail } = this.state;
    sendPasswordResetEmail(auth, loginEmail)
      .then((res) => {
        this.setState({ resentPassword: true });
        const { cookies } = this.props;
        cookies.set("resetPasswordEmail", loginEmail);
      })
      .catch((err) => {
        console.log("sendPasswordResetEmail err", err);
      });
  };

  onRegstierEmailChange = (event) => {
    let registerEmail = event.target.value;
    this.setState({ registerEmail, registerEmailError: false });
  };

  onRegstierPasswordChange = (event) => {
    let registerPassword = event.target.value;
    this.setState({ registerPassword, registerPasswordError: false });
  };

  onLoginEmailChange = (event) => {
    let loginEmail = event.target.value;
    this.setState({ loginEmail, loginEmailError: false });
  };

  onLoginPasswordChange = (event) => {
    let loginPassword = event.target.value;
    this.setState({ loginPassword, loginPasswordError: false });
  };

  onDeviceIdInput = (event) => {
    let deviceID = event.target.value;
    this.setState({
      deviceID,
      loginPasswordError: false,
      deviceIDError: false,
    });
  };

  register = (event) => {
    event.preventDefault();
    this.setState({ registerEmailError: false, registerPasswordError: false });

    let { registerEmail, registerPassword } = this.state;
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, registerEmail, registerPassword)
      .then((userCredential) => {
        const user = userCredential.user;

        let { deviceID } = this.state;
        let actionCodeSettings = {
          url: `https://quotron.co/?newDevice=${deviceID}`,
        };

        sendEmailVerification(auth.currentUser, actionCodeSettings)
          .then((res) => {
            //console.log("sendEmailVerification res", res);
          })
          .catch((err) => {
            //console.log(" sendEmailVerificationerr", err);
          });

        this.fillOutUser(user, deviceID);
        //this.props.setUserId(user.id);
      })
      .catch((error) => {
        //console.log("signup nowork", error.code);
        if (error?.code?.includes("auth/invalid-email")) {
          this.setState({ registerEmailError: "invalid email" });
        }
        if (error?.code?.includes("auth/email-already-in-use")) {
          this.setState({ registerEmailError: "email already in use" });
        }
        if (error?.code?.includes("auth/weak-password")) {
          this.setState({ registerPasswordError: "weak password" });
        }
      });
  };

  fillOutUser = async (user, deviceID) => {
    var options = {
      method: "GET",
      url: "https://ip.nf/me.json",
    };

    let location = await Axios.request(options).then((res) => {
      return {
        country: res.data.ip.country,
        city: res.data.ip.city,
        post_code: res.data.ip.post_code,
      };
    });

    let sku = await Axios.get(`https://quotron.co/getSku/${deviceID}`).then((res) => res.data);
    console.log("sku", sku);

    let obj = {
      uid: user.uid,
      location: location || {},
      stock: {
        AAPL: { name: "AAPL" },
        GOOGL: { name: "GOOGL" },
        TSLA: { name: "TSLA" },
      },
      forex: {
        AUDCAD: {
          name: "AUDCAD",
          inactive: true,
        },
        EURCAD: {
          name: "EURCAD",
          inactive: true,
        },
        EURCHF: {
          name: "EURCHF",
          inactive: true,
        },
        EURGBP: { name: "EURGBP" },
        EURJPY: { name: "EURJPY" },
        JPYMXN: { name: "JPYMXN", inactive: true },
      },
      crypto: {
        BTC: { name: "BTC" },
        ETH: { name: "ETH" },
      },
      markets: {
        "^VIX": false,
        "^N225": false,
        "^FTSE": false,
        "^DJI": false,
        "^GSPC": false,
        "^RUT": "^RUT",
        "^NYA": "^NYA",
        "^FVX": "^FVX",
        "^TNX": "^TNX",
        "^TYX": "^TYX",
        "^NDX": false,
        "^IXIC": "^IXIC",
        "^HSI": "^HSI",
        "^NSEI": "^NSEI",
        "^KS11": "^KS11",
        "000001-SS": "000001-SS",
        "^STI": "^STI",
        "^GDAXI": "^GDAXI",
        "^FCHI": "^FCHI",
        "^N100": "^N100",
        "^OMXS30": "^OMXS30",
        "^IBEX": "^IBEX",
        "^ATX": "^ATX",
        "^SSMI": "^SSMI",
        "^AEX": "^AEX",
        "^MXX": "^MXX",
        "^BVSP": "^BVSP",
        "^GSPTSE": "^GSPTSE",
        "^AXJO": "^AXJO",
        "^BFX": "^BFX",
        YMW00: "YMW00",
        NQW00: "NQW00",
        ESW00: "ESW00",
      },

      preferences: {
        upDown: "value",
        speed: 2,
        brightness: 5,
        multiLine: true,
        line1Data: ["stocks", "crypto", "nfl", "nba", "nhl", "mlb"],
        line2Data: ["markets", "comms", "forex"],
        lightmode: true,
        menu: {
          finance: [
            { name: "stocks", on: true },
            { name: "crypto", on: true },
            { name: "markets", on: true },
            { name: "forex", on: true },
            { name: "comms", on: true },
          ],
          sports: [
            { name: "mlb", on: true },
            { name: "nfl", on: true },
            { name: "nba", on: true },
            { name: "nhl", on: true },
            { name: "ncaa", on: true },
          ],
          weather: [{ name: "all", on: true }],
        },
      },
    };

    if (sku?.type == "stocks") {
      obj.preferences.menu = {
        finance: [{ name: "stocks", on: true }],
        sports: [],
        weather: [],
      };
    }

    if (sku?.type == "crypto") {
      obj.preferences.menu = {
        finance: [{ name: "crypto", on: true }],
        sports: [],
        weather: [],
      };
    }

    if (sku?.type == "sports") {
      obj.preferences.menu = {
        finance: [],
        sports: [
          { name: "nfl", on: true },
          { name: "nba", on: true },
          { name: "mlb", on: true },
          { name: "nhl", on: true },
          { name: "ncaa", on: false },
        ],
        weather: [],
      };
    }
    console.log("obj after", obj);

    var options = {
      method: "POST",
      url: "https://quotron.co/newUser/",
      data: {
        uid: user.uid,
        obj,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.request(options)
      .then((res) => {
        console.log("newUser res", res);
      })
      .catch((err) => {
        console.log("newUser err", err);
      });
  };

  login = async (event) => {
    event.preventDefault();
    let { loginEmail, loginPassword } = this.state;
    const auth = getAuth();

    loginEmail = loginEmail.replaceAll(" ", "");

    if (window.location.href?.includes("dev.quotron.co")) {
      let devLogin = false;
      await Axios.get(`https://quotron.co/devEmail/${loginEmail}`).then((res) => {
        if (res.data == "ok") {
          devLogin = true;
        }
      });
      if (!devLogin) {
        this.setState({ loginEmailError: "not dev" });
        return;
      }
    }

    signInWithEmailAndPassword(auth, loginEmail, loginPassword)
      .then((userCredential) => {
        const user = userCredential.user;
        //user.sendEmailVerification();
        console.log("isEmailVerified", user.emailVerified);
        //this.props.setUserId(user.id);
      })
      .catch((error) => {
        console.log("signin nowork", error, error?.code);
        if (error?.code?.includes("auth/invalid-email")) {
          this.setState({ loginEmailError: "invalid email" });
        }
        if (error?.code?.includes("auth/email-already-in-use")) {
          this.setState({ loginEmailError: "email already in use" });
        }
        if (error?.code?.includes("auth/weak-password")) {
          this.setState({ loginPasswordError: "weak password" });
        }
        if (error?.code?.includes("auth/user-not-found")) {
          this.setState({ loginEmailError: "user not found" });
        }
        if (error?.code?.includes("auth/wrong-password") || error?.message?.includes("INVALID_PASSWORD")) {
          this.setState({ loginPasswordError: "incorrect password" });
        }
        if (error?.code?.includes("auth/too-many-requests")) {
          this.setState({ loginEmailError: "too many requests" });
        }
      });
  };

  deviceSubmit = async (deviceID = this.state.deviceID) => {
    this.setState({ deviceIDError: false, validDeviceID: false });
    if (deviceID == "") {
      this.setState({ deviceIDError: true });
      return;
    }

    let formattedDevice = deviceID.replaceAll("-", "").replaceAll(" ", "");
    let hyphenFormat =
      formattedDevice.slice(0, 4) +
      "-" +
      formattedDevice.slice(4, 7) +
      "-" +
      formattedDevice.slice(7, 10) +
      "-" +
      formattedDevice.slice(10, 13) +
      "-" +
      formattedDevice.slice(13);
    let deviceInfo = await this.props.deviceCheck(hyphenFormat);
    console.log("deviceInfo", deviceInfo);
    if (deviceInfo.id && !deviceInfo.account) {
      this.setState({ validDeviceID: true, toggleAuth: "register" });
    } else {
      this.setState({ deviceIDError: true, toggleAuth: "register" });
    }
  };

  toggleAuth = (type = "login") => {
    let { toggleAuth } = this.state;
    let nextToggleAuth = type;
    if (type == "login" && toggleAuth == "login") {
      nextToggleAuth = "";
    }
    if (type == "register" && toggleAuth == "register") {
      nextToggleAuth = "";
    }
    this.setState({ toggleAuth: nextToggleAuth });
  };

  toggleEyePass = () => {
    console.log("toggleEyePass");
    let { eyePass } = this.state;
    this.setState({ eyePass: !eyePass });
  };

  render() {
    let {
      registerEmail,
      registerPassword,
      loginEmail,
      loginPassword,
      registerEmailError,
      registerPasswordError,
      loginEmailError,
      loginPasswordError,
      validDeviceID,
      deviceIDError,
      deviceID,
      quantity,
      quantityError,
      toggleAuth,
      phoneInput,
      phoneError,
      smsCode,
      eyePass,
    } = this.state;

    let { isMobile, showResetComplete, mode, screenWidth, darkmode, step } = this.props;

    let showLogin = (
      <>
        {false && (
          <form className={"registerBox"} onSubmit={this.phoneLogin} style={{ flexDirection: "row", alignItems: "center" }}>
            <input
              type="text"
              className={isMobile ? "authPhoneInputBoxMobile" : "authPhoneInputBox"}
              style={{
                backgroundColor: loginEmailError ? "#ff7f7f" : darkmode ? "#272727" : "#FFF",
                alignSelf: "flex-start",
                color: loginEmailError ? "#000" : darkmode ? "#ccc" : "#000",
              }}
              value={loginEmail}
              onChange={this.onLoginEmailChange}
              placeholder="404-555-1234"
            />
            <input type="submit" className={isMobile ? "authPhoneSubmitBox" : "authPhoneSubmitBox"} value="+" />
          </form>
        )}

        <form className={"registerBox"} onSubmit={this.login}>
          <input
            type="text"
            className={
              isMobile ? (darkmode ? "authInputBoxDarkMobile" : "authInputBoxMobile") : darkmode ? "authInputBoxDark" : "authInputBox"
            }
            style={{
              backgroundColor: loginEmailError ? "#ff7f7f" : darkmode ? "#272727" : "#FFF",
              color: loginEmailError ? "#000" : darkmode ? "#ccc" : "#000",
            }}
            value={loginEmail}
            onChange={this.onLoginEmailChange}
            placeholder="login@email.com"
          />
          <div className={"flexRow"} style={{ alignItems: "center", marginLeft: 4 }}>
            <input
              type="text"
              className={
                isMobile ? (darkmode ? "authInputBoxDarkMobile" : "authInputBoxMobile") : darkmode ? "authInputBoxDark" : "authInputBox"
              }
              style={{
                backgroundColor: loginPasswordError ? "#ff7f7f" : darkmode ? "#272727" : "#FFF",
                color: loginPasswordError ? "#000" : darkmode ? "#ccc" : "#000",
              }}
              value={loginPassword}
              type={eyePass ? "text" : "password"}
              onChange={this.onLoginPasswordChange}
              placeholder="*******"
            />
            {eyePass ? (
              <img
                onClick={() => this.toggleEyePass()}
                src={darkmode ? eyePassImg : eyePassDarkImg}
                style={{
                  marginTop: -8,
                  marginRight: -34,
                  height: 38,
                  width: 38,
                  backgroundColor: darkmode ? "#ccc" : "#333",
                  borderRadius: 6,
                }}
              />
            ) : (
              <img
                onClick={() => this.toggleEyePass()}
                src={darkmode ? eyePassDarkImg : eyePassImg}
                style={{
                  marginTop: -8,
                  marginRight: -34,
                  height: 38,
                  width: 38,
                  opacity: 0.8,
                }}
              />
            )}
          </div>
          <input
            type="submit"
            className={
              isMobile ? (darkmode ? "authSubmitBoxDarkMobile" : "authSubmitBoxMobile") : darkmode ? "authSubmitBoxDark" : "authSubmitBox"
            }
            value="Submit"
          />
          {mode == "verifyEmail" && (
            <div className={"authError"} style={{ marginTop: 14 }}>
              login to complete
            </div>
          )}
          {loginEmailError && (
            <div className={"authError"} style={{ marginTop: 14 }}>
              {loginEmailError}
            </div>
          )}
          {loginPasswordError && (
            <>
              {loginPasswordError == "incorrect password" ? (
                <>
                  <div onClick={this.resendPassword} className={darkmode ? "authForgotPasswordDark " : "authForgotPassword "}>
                    forgot password?
                  </div>
                  {this.state.resentPassword && (
                    <div className={darkmode ? "resetPasswordSentDark" : "resetPasswordSent"}>reset email sent</div>
                  )}
                </>
              ) : (
                <div className={"authError"} style={{ marginTop: 14 }}>
                  {loginPasswordError}
                </div>
              )}
            </>
          )}
        </form>
      </>
    );

    let showRegister = (
      <div className={"registerCol"}>
        <form className={"registerBox"} onSubmit={this.register}>
          <div
            className={darkmode ? "deviceidtextDark" : "deviceidtext"}
            style={{
              alignSelf: "flex-start",
              marginBottom: 6,
            }}
          >
            Register:
          </div>
          <input
            type="text"
            className={
              isMobile ? (darkmode ? "authInputBoxDarkMobile" : "authInputBoxMobile") : darkmode ? "authInputBoxDark" : "authInputBox"
            }
            style={{
              backgroundColor: registerEmailError ? "#ff7f7f" : darkmode ? "#272727" : "#FFF",
              color: registerEmailError ? "#000" : darkmode ? "#ccc" : "#000",
            }}
            value={registerEmail}
            onChange={this.onRegstierEmailChange}
            placeholder="register@email.com"
          />
          <div className={"flexRow"} style={{ alignItems: "center", marginLeft: 4 }}>
            <input
              type="text"
              className={
                isMobile ? (darkmode ? "authInputBoxDarkMobile" : "authInputBoxMobile") : darkmode ? "authInputBoxDark" : "authInputBox"
              }
              style={{
                backgroundColor: registerPasswordError ? "#ff7f7f" : darkmode ? "#272727" : "#FFF",
                color: registerPasswordError ? "#000" : darkmode ? "#ccc" : "#000",
              }}
              value={registerPassword}
              type={eyePass ? "text" : "password"}
              onChange={this.onRegstierPasswordChange}
              placeholder="*******"
            />
            {eyePass ? (
              <img
                onClick={() => this.toggleEyePass()}
                src={darkmode ? eyePassImg : eyePassDarkImg}
                style={{
                  marginTop: -8,
                  marginRight: -34,
                  height: 38,
                  width: 38,
                  backgroundColor: darkmode ? "#ccc" : "#333",
                  borderRadius: 6,
                }}
              />
            ) : (
              <img
                onClick={() => this.toggleEyePass()}
                src={darkmode ? eyePassDarkImg : eyePassImg}
                style={{
                  marginTop: -8,
                  marginRight: -34,
                  height: 38,
                  width: 38,
                  opacity: 0.8,
                }}
              />
            )}
          </div>
          <input
            type="submit"
            className={
              isMobile ? (darkmode ? "authSubmitBoxDarkMobile" : "authSubmitBoxMobile") : darkmode ? "authSubmitBoxDark" : "authSubmitBox"
            }
            value="Register"
          />
          {registerEmailError && (
            <div className={"authError"} style={{ marginTop: 14, textAlign: "center" }}>
              {registerEmailError}
              {registerEmailError == "email already in use" && (
                <div style={{ marginTop: 10, width: 230, textAlign: "center" }}>
                  {"if you already have an account-- login and add new devices under settings"}
                </div>
              )}
            </div>
          )}
          {registerPasswordError && (
            <div className={"authError"} style={{ marginTop: 14 }}>
              {registerPasswordError}
            </div>
          )}
        </form>
        <div style={{ display: "none" }}>
          <div style={{ opacity: 0.5, marginTop: 8, marginBottom: 12 }}> - or - </div>
          <div
            className={isMobile ? "deviceRegisterBoxMobile" : "deviceRegisterBox"}
            style={{ marginBottom: 10 }}
            onClick={() => this.registerProvider("google")}
          >
            google
          </div>
          <div
            className={isMobile ? "deviceRegisterBoxMobile" : "deviceRegisterBox"}
            style={{ marginBottom: 10 }}
            onClick={() => this.registerProvider("twitter")}
          >
            twitter
          </div>
        </div>
      </div>
    );

    let totalValue = quantity ? quantity * 200 : 200;
    totalValue = totalValue.toFixed(2);

    let registerBoxCss =
      toggleAuth == "register"
        ? darkmode
          ? "deviceRegisterBoxDark-Active"
          : "deviceRegisterBox-Active"
        : darkmode
        ? "deviceRegisterBoxDark"
        : "deviceRegisterBox";
    if (isMobile) {
      registerBoxCss =
        toggleAuth == "register"
          ? darkmode
            ? "deviceRegisterBoxMobileDark-Active"
            : "deviceRegisterBoxMobile-Active"
          : darkmode
          ? "deviceRegisterBoxMobileDark"
          : "deviceRegisterBoxMobile";
    }

    let loginBoxCss =
      toggleAuth == "login"
        ? darkmode
          ? "deviceRegisterBoxDark-Active"
          : "deviceRegisterBox-Active"
        : darkmode
        ? "deviceRegisterBoxDark"
        : "deviceRegisterBox";
    if (isMobile) {
      loginBoxCss =
        toggleAuth == "login"
          ? darkmode
            ? "deviceRegisterBoxMobileDark-Active"
            : "deviceRegisterBoxMobile-Active"
          : darkmode
          ? "deviceRegisterBoxMobileDark"
          : "deviceRegisterBoxMobile";
    }

    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: isMobile ? "center" : "flex-start",
            marginTop: isMobile ? 80 : 20,
            alignSelf: isMobile ? "stretch" : "auto",
          }}
        >
          <div
            id="sign-in-button"
            style={{
              height: 0,
              width: 0,
            }}
          />
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignSelf: "stretch",
              }}
            >
              {!step && (
                <div>
                  <div
                    onClick={() => this.toggleAuth("register")}
                    className={registerBoxCss}
                    value="PreOrder"
                    style={{ margin: 20, opacity: 0.8 }}
                  >
                    Register
                  </div>

                  <div
                    onClick={() => this.toggleAuth()}
                    className={loginBoxCss}
                    value="PreOrder"
                    style={{ margin: 20, opacity: 0.8, marginTop: -4 }}
                  >
                    Login
                  </div>
                </div>
              )}
              {step && (
                <div
                  className={"flexCol"}
                  style={{
                    flex: 1,
                  }}
                >
                  <div
                    className={darkmode ? "deviceidtextDark" : "deviceidtext"}
                    style={{
                      fontSize: 19,
                      alignSelf: "center",
                      border: "0px solid #777",
                      borderBottomWidth: 1,
                      padding: 3,
                      marginBottom: 10,
                    }}
                  >
                    {step == "register" && "register account"}
                    {step == "login" && "login"}
                  </div>
                </div>
              )}
            </div>
            {toggleAuth == "register" && (
              <>
                {validDeviceID ? (
                  <>{mode != "verifyEmail" && showRegister}</>
                ) : (
                  <div>
                    <div className={darkmode ? "deviceidtextDark" : "deviceidtext"}>Device ID:</div>
                    <input
                      type="text"
                      className={
                        isMobile
                          ? darkmode
                            ? "authInputBoxDarkMobile"
                            : "authInputBoxMobile"
                          : darkmode
                          ? "authInputBoxDark"
                          : "authInputBox"
                      }
                      style={{
                        backgroundColor: deviceIDError ? "#ff7f7f" : darkmode ? "#272727" : "#FFF",
                        marginBottom: 8,
                        color: deviceIDError ? "#000" : darkmode ? "#ccc" : "#000",
                      }}
                      value={deviceID}
                      onChange={this.onDeviceIdInput}
                      placeholder="123-456-789"
                    />
                    <div
                      onClick={() => this.deviceSubmit()}
                      className={
                        isMobile
                          ? darkmode
                            ? "deviceRegisterBoxSubmitDarkMobile"
                            : "deviceRegisterBoxSubmitMobile"
                          : darkmode
                          ? "deviceRegisterBoxSubmitDark"
                          : "deviceRegisterBoxSubmit"
                      }
                      style={{ margin: "auto" }}
                      value="Register"
                    >
                      Submit
                    </div>
                  </div>
                )}
              </>
            )}
            {toggleAuth == "login" && (
              <>
                {this.state.showCode ? (
                  <div
                    className={""}
                    style={{
                      minWidth: isMobile ? screenWidth - 58 : 300,
                      marginTop: isMobile ? 14 : 20,
                      marginBottom: isMobile ? 20 : 10,
                      borderRadius: 4,
                      height: 41,
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderWidth: 0,
                    }}
                  >
                    <div
                      className={"profileTextSub"}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginRight: 10,
                      }}
                    >
                      sms code:
                    </div>

                    <input
                      type="text"
                      className={"searchSubmitBoxCode"}
                      style={{
                        width: 104,
                        fontSize: 16.5,
                        paddingLeft: 6,
                      }}
                      value={smsCode}
                      onChange={this.onSmsCode}
                      placeholder={"ØØØØØØ"}
                    />
                  </div>
                ) : (
                  <>
                    <div style={{ marginTop: 20 }}>{showLogin}</div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        {showResetComplete && <div className={"resetComplete"}>reset password complete</div>}
      </>
    );
  }
}

export default Auth;
