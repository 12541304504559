import React, { useState, useEffect } from "react";
import Axios from "axios";
import Switch from "react-input-switch";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

let Test = ({ isMobile, uid, bothArr, userDevices }) => {
  let [arr, setArr] = useState([]);
  let [target, setTarget] = useState("backup");
  let [active, setActive] = useState(-1);
  let [belongs, setBelongs] = useState([]);

  useEffect(() => {
    getBelongs();
  }, [userDevices]);

  let getBelongs = () => {
    let bArr = userDevices?.filter((a) => a?.account?.uid);
    setBelongs(bArr);
  };

  let run = async () => {
    const source = Axios.CancelToken.source();

    for (const device of belongs) {
      let url = "http://quotron.xyz:3000";
      if (target == "prod") {
        url = "http://quotron.co:3000";
      }
      if (!device?.account?.uid) return;
      var options = {
        method: "GET",
        url: `${url}/stream/main?device_id=${device.id}`,
        headers: {
          "Content-Type": "application/json",
        },
        cancelToken: source.token,
      };
      try {
        const timeStart = Date.now();
        const res = await Axios.request(options);
        const timeEnd = Date.now() - timeStart;

        setArr((prevArr) => [
          ...prevArr,
          { ...res.data, timeEnd, box: device.id },
        ]);

        await new Promise((resolve) => setTimeout(resolve, 500));
      } catch (error) {
        if (error.response?.status === 500) {
          setArr((prevArr) => [
            ...prevArr,
            { message1: { txt: "500 Error" }, timeEnd: 0, box: device.id },
          ]);
        }
        if (Axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
          break;
        } else {
          console.error("Error fetching data:", error);
        }
      }
    }
    // Clean up on unmount
    return () => {
      source.cancel("Component unmounted and request is cancelled");
    };
  };

  let e1 = 0;
  let e2 = 0;
  let e3 = 0;
  let e4 = 0;

  let bars = arr?.map((a, k) => {
    let length1 = a.message1?.txt?.length || 0;
    let length2 = a.message2?.txt?.length || 0;
    let color = "#fbf7f5";
    let bg = k == active ? "#888" : "#fbf7f5";
    if (
      a.message1?.txt?.includes("undefined") ||
      a.message2?.txt?.includes("undefined")
    ) {
      e1++;
      bg = "yellow";
    }
    if (
      a.message1?.txt?.includes("register device at quotron.co") ||
      a.message2?.txt?.includes("register device at quotron.co")
    ) {
      e2++;
      bg = "orange";
    }
    if (length1 == 0 && length2 == 0) {
      e3++;
      bg = "pink";
    }
    if (a.message1?.txt?.includes("500 Error")) {
      e4++;
      bg = "red";
    }
    return (
      <div
        key={a.box}
        onClick={() => (k == active ? setActive(-1) : setActive(k))}
      >
        <div
          style={{
            ...local.bar,
            backgroundColor: bg,
          }}
        >
          <div style={{ width: 60 }}>{length1}</div>
          <div
            style={{
              width: 60,
              border: "0px solid #ccc",
              borderLeftWidth: 1,
              height: 35,
            }}
          >
            {length2}
          </div>
          <div
            style={{
              width: 100,
              border: "0px solid #ccc",
              borderLeftWidth: 1,
              height: 35,
            }}
          >
            {a.timeEnd} ms
          </div>
          <div
            style={{
              width: 180,
              border: "0px solid #ccc",
              borderLeftWidth: 1,
              height: 35,
            }}
          >
            {a.box}
          </div>
        </div>
        {active == k && (
          <div
            style={{
              fontSize: 14,
              maxWidth: 400,
              maxHeight: 200,
              overflow: "scroll",
            }}
          >
            {a.message1?.txt}
            <div style={{ height: 10 }} />
            <div>{`----`}</div>
            <div style={{ height: 10 }} />
            {a.message2?.txt}
            <div style={{ height: 10 }} />
          </div>
        )}
      </div>
    );
  });

  let cataData = (data) => {
    const categories = {
      "0-100": 0,
      "101-200": 0,
      "201-300": 0,
      "301-400": 0,
      "401-500": 0,
      "500+": 0,
    };

    data.forEach((item) => {
      const { timeEnd } = item;
      if (timeEnd <= 100) categories["0-100"]++;
      else if (timeEnd <= 200) categories["101-200"]++;
      else if (timeEnd <= 300) categories["201-300"]++;
      else if (timeEnd <= 400) categories["301-400"]++;
      else if (timeEnd <= 500) categories["401-500"]++;
      else categories["500+"]++;
    });

    return Object.entries(categories).map(([name, value]) => ({ name, value }));
  };

  let cat = cataData(arr);

  return (
    <div className={"prefMenu flexCol"}>
      <div className={"settingsTitle"} style={local.title}>
        <div>test suite</div>
        <div>
          {`backup `}
          <Switch
            style={{ height: 18, width: 28 }}
            styles={{
              track: {
                backgroundColor: "#bbb",
              },
              trackChecked: {
                backgroundColor: "#99ace0",
              },
            }}
            onChange={() => setTarget(target == "prod" ? "backup" : "prod")}
            value={target == "prod" ? 1 : 0}
          />
          {` prod`}
        </div>
      </div>
      {arr?.length > 0 && (
        <div style={local.start}>{` ${arr?.length}/${belongs?.length}`}</div>
      )}
      {arr?.length <= 0 && (
        <div style={local.start} onClick={() => run()}>{`start`}</div>
      )}
      <div style={{ marginLeft: -48 }}>
        <BarChart
          width={400}
          height={200}
          data={cat}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="value" fill="#888" />
        </BarChart>
      </div>
      {arr?.length > 0 && (
        <div>
          {`results`}
          <div style={{ flexDirection: "row", display: "flex" }}>
            <div style={{ backgroundColor: "yellow", width: 100 }}>
              undefined
            </div>
            {e1}
          </div>

          <div style={{ flexDirection: "row", display: "flex" }}>
            <div style={{ backgroundColor: "orange", width: 100 }}>
              register
            </div>
            {e2}
          </div>
          <div style={{ flexDirection: "row", display: "flex" }}>
            <div style={{ backgroundColor: "pink", width: 100 }}>zero data</div>
            {e3}
          </div>
          <div style={{ flexDirection: "row", display: "flex" }}>
            <div style={{ backgroundColor: "red", width: 100 }}>500</div>
            {e4}
          </div>
        </div>
      )}
      <div
        style={{
          marginBottom: 10,
          marginTop: 5,
          flexDirection: "row",
          display: "flex",
        }}
      >
        <div
          style={{
            width: 60,
            border: "1px solid #888",
            borderRightWidth: 0,
            paddingLeft: 4,
            paddingTop: 4,
            height: 30,
          }}
        >{`line 1`}</div>
        <div
          style={{
            width: 60,
            border: "1px solid #888",
            borderRightWidth: 0,
            paddingLeft: 4,
            paddingTop: 4,
            height: 30,
          }}
        >{`line 2`}</div>
        <div
          style={{
            width: 100,
            border: "1px solid #888",
            borderRightWidth: 0,
            paddingLeft: 4,
            paddingTop: 4,
            height: 30,
          }}
        >{`ms`}</div>
        <div
          style={{
            width: 180,
            border: "1px solid #888",
            paddingLeft: 4,
            paddingTop: 4,
            height: 30,
          }}
        >{`box id`}</div>
      </div>
      <div className={"flexCol"}>{bars}</div>
    </div>
  );
};

let local = {
  bar: {
    display: "flex",
    flexDirection: "row",
    height: 35,
    border: "1px solid #888",
    marginBottom: -1,
  },
  start: {
    display: "flex",
    flexDirection: "column",
    height: 35,
    width: 100,
    border: "1px solid #888",
    justifyContent: "center",
    marginTop: 6,
    paddingLeft: 4,
    cursor: "pointer",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
    marginBottom: 4,
    width: 320,
    border: "0px solid #ccc",
    borderBottomWidth: 1,
  },
};
export default Test;
