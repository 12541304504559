import React, { useState } from "react";
import ReactSlider from "react-slider";

let UpcomingSlider = ({ darkmode, setValue, value }) => {
  let [text, setText] = useState("days out:");

  let changeText = (res) => {
    if (res == 0) {
      setText("live only:");
    } else {
      setText("days out:");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignSelf: "stretch",
        position: "relative",
        alignItems: "center",
        justifyContent: "flex-end",
        marginTop: 2,
        paddingRight: 6,
        marginBottom: 2,
      }}
    >
      <div style={{ marginRight: 5, marginTop: -7, width: 65 }}>{text}</div>

      <ReactSlider
        className="horizontal-slider-sports"
        marks
        markClassName="example-mark"
        defaultValue={value}
        min={0}
        max={14}
        thumbClassName={darkmode ? "example-thumb" : "example-thumb-light"}
        trackClassName="example-track"
        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
        onAfterChange={(result) => {
          setValue(result);
          changeText(result);
        }}
      />
    </div>
  );
};

export default UpcomingSlider;
