import React, { useState, useRef, useEffect } from "react";
import Axios from "axios";

import plus from "../../imgs/plus.png";
import plusW from "../../imgs/plusW.png";

import checkImgW from "../../imgs/checkW.png";
import checkImg from "../../imgs/check.png";

//make finance atleast 1

let Store = ({ uid, darkmode, preferences, togglePrefSettings }) => {
  let [menu, setMenu] = useState(preferences.menu);
  console.log("store menu", menu);

  let menuTotal = {
    finance: [
      { name: "stocks", on: true },
      { name: "crypto", on: true },
      { name: "markets", on: true },
      { name: "forex", on: true },
      { name: "comms", on: true },
    ],
    sports: [
      { name: "mlb", on: true },
      { name: "nfl", on: true },
      { name: "ncaa", on: true },
      { name: "nba", on: true },
      { name: "nhl", on: true },
    ],
    weather: [
      {
        name: "all",
        on: true,
      },
    ],
  };

  //payment idea
  /*
      <div
        style={{
          opacity: 0.9,
          fontSize: 15,
          marginRight: 8,
        }}
        className={"profileTextSub"}
      >{`$5/ mo`}</div>
  */

  let makeActive = async (type, name) => {
    console.log("makeActive", type, name);
    let newMenu = { ...menu };
    if (!newMenu[type]) {
      newMenu[type] = [];
    }
    newMenu[type].push({ name, on: true });
    setMenu(newMenu);
    //updateMenu(newMenu);
    let toggleRes = await togglePrefSettings("menu", newMenu);
    console.log("toggleRes", toggleRes);
    //sendMenu(newMenu);
  };

  let makeInactive = async (type, name) => {
    console.log("makeInactive", type, name);
    let newMenu = { ...menu };
    newMenu[type] = newMenu[type].filter((a) => a.name != name);
    console.log("newMenu", newMenu);
    setMenu(newMenu);
    //updateMenu(newMenu);
    let toggleRes = await togglePrefSettings("menu", newMenu);
    console.log("toggleRes", toggleRes);
    //sendMenu(newMenu);
  };

  let sendMenu = async (newMenu) => {
    var options = {
      method: "POST",
      url: "https://quotron.co/sendMenu",
      data: {
        uid,
        menu: newMenu,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };

    console.log("sendMenu", options);

    try {
      let res = await Axios.request(options);
      console.log("sendMenu res", res);
    } catch (err) {
      console.log("addToUser err", err);
    }

    console.log("res", res);
  };

  let financeRow = menuTotal.finance.map((a, k) => {
    let isActive = false;
    if (menu.finance.find((b) => b.name == a.name)?.name) {
      isActive = true;
    }

    if (isActive) {
      return (
        <div style={darkmode ? { ...local.s2ActiveDark } : { ...local.s2Active }} onClick={() => makeInactive("finance", a.name)}>
          <div className={"profileTextSub"} style={{ flex: 1, color: darkmode ? "#bbb" : "#000" }}>
            {a.name}
          </div>
          <div style={darkmode ? local.checkDark : local.check}>
            <img src={darkmode ? checkImg : checkImgW} style={{ height: 19, width: 19, opacity: darkmode ? 1 : 0.9 }} />
          </div>
        </div>
      );
    }
    return (
      <div style={darkmode ? { ...local.s2Dark } : { ...local.s2 }} onClick={() => makeActive("finance", a.name)}>
        <div className={"profileTextSub"} style={{ flex: 1, color: darkmode ? "#bbb" : "#000" }}>
          {a.name}
        </div>
        <div style={darkmode ? local.plusDark : local.plus}>
          <img src={darkmode ? plusW : plus} style={{ height: 18, width: 18, opacity: darkmode ? 0.5 : 0.6 }} />
        </div>
      </div>
    );
  });

  let sportsRow = menuTotal.sports.map((a, k) => {
    let isActive = false;
    if (menu.sports.find((b) => b.name == a.name)?.name) {
      isActive = true;
    }

    let title = a.name?.toUpperCase();
    console.log("title", title);

    if (isActive) {
      return (
        <div style={darkmode ? { ...local.s2ActiveDark } : { ...local.s2Active }} onClick={() => makeInactive("sports", a.name)}>
          <div className={"profileTextSub"} style={{ flex: 1, color: darkmode ? "#bbb" : "#000" }}>
            {title}
          </div>
          <div style={darkmode ? local.checkDark : local.check}>
            <img src={darkmode ? checkImg : checkImgW} style={{ height: 19, width: 19, opacity: darkmode ? 1 : 0.9 }} />
          </div>
        </div>
      );
    }
    return (
      <div style={darkmode ? { ...local.s2Dark } : { ...local.s2 }} onClick={() => makeActive("sports", a.name)}>
        <div className={"profileTextSub"} style={{ flex: 1, color: darkmode ? "#bbb" : "#000" }}>
          {title}
        </div>
        <div style={darkmode ? local.plusDark : local.plus}>
          <img src={darkmode ? plusW : plus} style={{ height: 18, width: 18, opacity: darkmode ? 0.5 : 0.6 }} />
        </div>
      </div>
    );
  });

  let weatherRow = menuTotal.weather.map((a, k) => {
    let isActive = false;
    if (menu.weather.find((b) => b.name == a.name)?.name) {
      isActive = true;
    }

    let title = "weather";

    if (isActive) {
      return (
        <div style={darkmode ? { ...local.s2ActiveDark } : { ...local.s2Active }} onClick={() => makeInactive("weather", a.name)}>
          <div className={"profileTextSub"} style={{ flex: 1, color: darkmode ? "#bbb" : "#000" }}>
            {title}
          </div>
          <div style={darkmode ? local.checkDark : local.check}>
            <img src={darkmode ? checkImg : checkImgW} style={{ height: 19, width: 19, opacity: darkmode ? 1 : 0.9 }} />
          </div>
        </div>
      );
    }
    return (
      <div style={darkmode ? { ...local.s2Dark } : { ...local.s2 }} onClick={() => makeActive("weather", a.name)}>
        <div className={"profileTextSub"} style={{ flex: 1, color: darkmode ? "#bbb" : "#000" }}>
          {title}
        </div>
        <div style={darkmode ? local.plusDark : local.plus}>
          <img src={darkmode ? plusW : plus} style={{ height: 18, width: 18, opacity: darkmode ? 0.5 : 0.6 }} />
        </div>
      </div>
    );
  });

  return (
    <div className={"prefMenu"} style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <div style={local.box}>
        <div style={local.header}>
          <div style={local.header2}>{`data feeds`}</div>
        </div>
        <div style={{ ...local.s1 }}>{"finance"}</div>
        {financeRow}
        <div style={{ ...local.s1 }}>{"sports"}</div>
        {sportsRow}
        <div style={{ ...local.s1 }}>{"weather"}</div>
        {weatherRow}
      </div>
    </div>
  );
};

let local = {
  box: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    paddingBottom: 20,
    width: 356,
  },
  header: {
    alignSelf: "stretch",
    border: "#aaa solid 0px",
    borderBottomWidth: 1,
    marginBottom: 6,
    marginRight: 10,
  },
  header2: {
    marginTop: 20,
    marginBottom: -1,
    paddingLeft: 4,
    display: "flex",
    flexDirection: "row",
  },
  divider: {
    color: "#999",
    width: 50,
    marginLeft: 2,
    border: "0px solid #aaa",
    borderBottomWidth: 1,
    alignSelf: "flex-start",
    marginBottom: 8,
  },
  s1: {
    height: 40,
    backgroundColor: "#555",
    color: "#fff",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    alignSelf: "baseline",
    paddingLeft: 6,
    paddingRight: 14,
    marginTop: 10,
  },
  s2: {
    height: 40,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    //width: 270,
    paddingLeft: 6,
    border: "1px solid #ccc",
    marginTop: -1,
  },
  s2Dark: {
    height: 40,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    //width: 270,
    paddingLeft: 6,
    border: "1px solid #444",
    marginTop: -1,
  },
  s2Active: {
    height: 40,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    width: 200,
    paddingLeft: 6,
    border: "1px solid #ccc",
    marginTop: -1,
  },
  s2ActiveDark: {
    height: 40,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    width: 200,
    paddingLeft: 6,
    border: "1px solid #444",
    marginTop: -1,
  },
  check: {
    height: 40,
    border: "1px solid #ccc",
    borderRightWidth: 0,
    borderLeftWidth: 0,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#999",
    backgroundColor: "#637dc8",
  },
  checkDark: {
    height: 40,
    border: "1px solid #444",
    borderRightWidth: 0,
    borderLeftWidth: 0,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#999",
    backgroundColor: "#637dc8",
  },
  plus: {
    height: 40,
    border: "0px solid #ddd",
    borderLeftWidth: 1,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#999",
  },
  plusDark: {
    height: 40,
    border: "0px solid #444",
    borderLeftWidth: 1,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#999",
  },
};

export default Store;
