import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import SwipeToDelete from "react-swipe-to-delete-ios";
import plus from "../../imgs/plus.png";
import closeX from "../../imgs/closeX.png";
import closeXDark from "../../imgs/closeXDark.png";
import closeXWhite from "../../imgs/closeXWhite.png";
import RssButton from "./RssButton";

function ListCrypto(props) {
  let { cryptoData, cryptoInput, activeCrypto, darkmode, isMobile } = props;
  let showFavs = cryptoData.map((a, k) => {
    let SparkChart = <div></div>;
    let chartData = [];
    let sparkline = a.sparkline_in_7d.price;
    sparkline = sparkline.slice(Math.max(sparkline.length - 24, 1));
    let yHigh = Math.max(...sparkline);
    let ylow = Math.min(...sparkline);

    chartData = sparkline.map((b) => {
      return { price: b };
    });

    //https://codesandbox.io/s/focused-euclid-qdlyi?file=/src/tests/ComparisonChart.js
    SparkChart = (
      <LineChart width={100} height={40} data={chartData}>
        <Line
          type="monotone"
          dataKey="price"
          stroke="#aaa"
          strokeWidth={1}
          dot={false}
        />
        <YAxis domain={[ylow, yHigh]} hide />
      </LineChart>
    );
    let sparkBoxCss = "sparkLineBox";
    let weatherButtonCss = darkmode ? "favListItemDark" : "favListItem";
    if (activeCrypto == k) {
      weatherButtonCss = darkmode
        ? "favListItemDarkActive"
        : "favListItemActive";
      sparkBoxCss = "sparkLineBoxDark";
    }
    let showPrice = Number(a.current_price);
    if (showPrice < 0.01) {
      showPrice = showPrice.toExponential(3);
    } else {
      showPrice = showPrice.toFixed(2);
    }

    if (a.symbol == "BTC") {
      showPrice = Number(a.current_price);
    }

    let showChange = Number(a.price_change_percentage_24h_in_currency).toFixed(
      2
    );

    //showChange = Number(a.price_change_percentage_7d_in_currency).toFixed(2);

    let changeColor = darkmode ? "#5fff5f" : "#0c870c";
    let showCaret = "fa fa-caret-up";
    if (activeCrypto == k) {
      changeColor = "#5fff5f";
    }
    if (showChange < 0) {
      changeColor = darkmode ? "#ef0101" : "#bd0101";
      showCaret = "fa fa-caret-down";
      if (activeCrypto == k) {
        changeColor = darkmode ? "#ef0101" : "#ffbe9e";
      }
    }

    let showSymbol = a.symbol.replace("~", ".");

    return (
      <SwipeToDelete
        key={a.symbol}
        onDelete={() => props.removeCrypto(a.symbol)}
        disabled={!isMobile}
        deleteWidth={40}
        deleteThreshold={40}
        deleteColor={"none"}
        className={"swipeRow"}
        deleteComponent={
          isMobile ? (
            <img
              src={darkmode ? closeXDark : closeX}
              className={"removeButtonXSwipe"}
            />
          ) : (
            <div />
          )
        }
      >
        <div key={k} className={"favListBox"}>
          <button
            className={weatherButtonCss}
            onClick={() =>
              isMobile
                ? props.inactiveCrypto(showSymbol, a.inactive)
                : props.makeCryptoActive(k)
            }
          >
            <div className={"favListLeft"}>
              <RssButton
                onClick={() => props.inactiveCrypto(showSymbol, a.inactive)}
                activeRow={true}
                inactive={a.inactive}
                darkmode={darkmode}
              />
              <div className={"favListSymbol"}>{showSymbol}</div>
              <div className={"favListName"}>{a.name}</div>
            </div>
            <div className={"favListRight"}>
              <div className={sparkBoxCss}>{SparkChart}</div>
              <div
                className={`${showCaret} favItemCaret`}
                style={{ color: changeColor }}
              ></div>
              <div
                className={darkmode ? "favItemChangeDark" : "favItemChange"}
                style={{ color: changeColor }}
              >
                {`${showChange}%`}
              </div>
              <div className={"favItemPrice"}>{showPrice}</div>
            </div>
          </button>
          {activeCrypto == k && !isMobile && (
            <button onClick={() => props.removeCrypto(a.symbol)}>
              <img
                src={darkmode ? closeXWhite : closeX}
                className={"removeButtonX"}
              />
            </button>
          )}
        </div>
      </SwipeToDelete>
    );
  });

  let showCryptoSort = "mktCap";
  let showSortCaret = "fa-caret-down";
  if (props.cryptoSort.includes("mkt")) {
    showCryptoSort = "mktCap";
  }
  if (props.cryptoSort.includes("perc")) {
    showCryptoSort = "change(%)";
  }
  if (props.cryptoSort.includes("price")) {
    showCryptoSort = "price";
  }
  if (props.cryptoSort.includes("abc")) {
    showCryptoSort = "abc";
  }
  if (props.cryptoSort.includes("A")) {
    showSortCaret = "fa-caret-up";
  }

  return (
    <>
      <div className={darkmode ? "prefBoxDark" : "prefBox"}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignSelf: "stretch",
            justifyContent: "space-between",
            paddingLeft: 5,
          }}
        >
          <div className={"weatherForm"}>
            <form
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              onSubmit={props.onCryptoSubmit}
            >
              <input
                type="text"
                className={
                  darkmode
                    ? "searchSubmitBoxCryptoDark"
                    : "searchSubmitBoxCrypto"
                }
                value={cryptoInput}
                onChange={props.onCryptoInput}
                placeholder="CRYPTO"
              />
              <div className={"searchSubmitButtonBox"}>
                <input
                  type="submit"
                  className={
                    darkmode ? "searchSubmitButtonDark" : "searchSubmitButton"
                  }
                  value=""
                />
                <div
                  className={darkmode ? "submitPlusFontDark" : "submitPlusFont"}
                >
                  +
                </div>
              </div>
            </form>
          </div>
          <div
            onClick={() => props.alternateSort("crypto")}
            className={"favSort noselect"}
          >
            <div className={`fa ${showSortCaret} favSortCaret noselect`}></div>
            <div className={"noselect"}>{showCryptoSort}</div>
          </div>
        </div>

        <div className={"favList"}>{showFavs}</div>
      </div>
    </>
  );
}

export default ListCrypto;
