import React, { Component } from "react";
import Switch from "react-input-switch";
import { getDatabase, ref, onValue, child, get, set } from "firebase/database";
import Axios from "axios";
import closeX from "../../imgs/closeX.png";
import closeXDark from "../../imgs/closeXDark.png";

import BrightnessSlider from "../settings/BrightnessSlider";
import SpeedSlider from "../settings/SpeedSlider";
import TimezoneSelect from "../settings/TimezoneSelect";
import Sleep from "../settings/Sleep";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceID: "",
      deviceIDError: false,
      activeDevice: null,
      line1: ["stocks"],
      line2: [""],
      textString: "",
      canSleep: false,
    };
  }

  componentDidMount() {
    this.checkDeviceVer();
  }

  checkVer = (baseVersion, queryVersion) => {
    const baseParts = baseVersion.split(".").map(Number);
    const queryParts = queryVersion.split(".").map(Number);

    for (let i = 0; i < 3; i++) {
      if (queryParts[i] > baseParts[i]) {
        return true;
      } else if (queryParts[i] < baseParts[i]) {
        return false;
      }
    }
    return true; // Versions are equal
  };

  checkDeviceVer = async () => {
    this.props.deviceArr?.map(async (a) => {
      Axios.get(`https://quotron.co/deviceVer/${a.id}/${this.props.uid}`).then(
        (res) => {
          if (res?.data?.versionArr) {
            let vArr = res?.data?.versionArr;
            let thisVer = vArr[vArr.length - 1];
            if (this.checkVer("2.14.5", thisVer)) {
              this.setState({ canSleep: true });
            } else {
              //  console.log("nop");
            }
          }
        }
      );
    });
  };

  registerDevice = async (deviceID = this.state.deviceID) => {
    let { uid, getUserData } = this.props;
    this.setState({ deviceIDError: false });
    if (deviceID == "") {
      this.setState({ deviceIDError: true });
      return;
    }

    let formattedDevice = deviceID.replaceAll("-", "").replaceAll(" ", "");
    deviceID =
      formattedDevice.slice(0, 4) +
      "-" +
      formattedDevice.slice(4, 7) +
      "-" +
      formattedDevice.slice(7, 10) +
      "-" +
      formattedDevice.slice(10, 13) +
      "-" +
      formattedDevice.slice(13);

    Axios.get(`https://quotron.co/deviceCheck/${deviceID}/${uid}`).then(
      async (res) => {
        if (res.data?.id && !res.data?.account) {
          await Axios.get(`https://quotron.co/setDeviceId/${deviceID}/${uid}`);
          this.setState({ deviceID: "", activeDevice: null });
          getUserData();
        } else {
          this.setState({ deviceIDError: true });
        }
      }
    );
  };

  setTimezone = (pick) => {
    let db = getDatabase();
    let { uid } = this.props;
    set(ref(db, "users/" + uid + "/preferences/timezone"), pick.value);
    this.props.togglePrefSettings("timezone", pick.value);
  };

  onDeviceIdInput = (event) => {
    let deviceID = event.target.value;
    this.setState({
      deviceID,
      deviceIDError: false,
    });
  };

  makeDeviceActive = (k) => {
    this.setState({ activeDevice: k });
  };

  removeDevice = async (deviceId) => {
    let { uid, getUserData } = this.props;
    await Axios.get(`https://quotron.co/removeDeviceId/${deviceId}/${uid}`);
    getUserData();
  };

  onTextChange = (event) => {
    let textString = event.target.value;
    this.setState({ textString });
  };

  onTextSubmit = (event) => {
    event.preventDefault();
    let { textString } = this.state;
    let { uid } = this.props;

    var options = {
      method: "POST",
      url: "https://quotron.co/customString/",
      data: {
        uid,
        obj: { string: textString, times: 3 },
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.request(options)
      .then((res) => {
        console.log("customString", res);
        this.setState({ textString: "" });
      })
      .catch((err) => {
        console.log("customString err", err);
      });
  };

  setSpeed = (speed) => {
    let { uid } = this.props;
    this.props.togglePrefSettings("speed", speed);
  };

  setBrightness = (brightness) => {
    let { uid } = this.props;
    this.props.togglePrefSettings("brightness", brightness);
  };

  setDisableOthers = () => {
    console.log("setDisableOthers");
  };

  enableSportsOverride = (override) => {
    let { uid } = this.props;
    this.props.togglePrefSettings("override", override);
  };

  setLine1 = (type) => {
    let line1Data = this.props?.preferences?.line1Data || [];
    if (line1Data?.includes(type)) {
      line1Data = line1Data?.filter((a) => a != type);
    } else {
      if (!line1Data) {
        line1Data = [];
      }
      line1Data.push(type);
    }
    let { uid } = this.props;
    this.props.togglePrefSettings("line1Data", line1Data);
  };

  setLine2 = (type) => {
    let line2Data = this.props?.preferences?.line2Data || [];
    if (line2Data?.includes(type)) {
      line2Data = line2Data?.filter((a) => a != type);
    } else {
      if (!line2Data) {
        line2Data = [];
      }
      line2Data.push(type);
    }
    let { uid } = this.props;
    this.props.togglePrefSettings("line2Data", line2Data);
  };

  render() {
    let { deviceIDError, deviceID, activeDevice, textString } = this.state;
    let {
      isMobile,
      preferences,
      darkmode,
      handleDarkModeChange,
      isAdmin,
      isTrail,
    } = this.props;

    let line1Data = this.props?.preferences?.line1Data || [];
    let line2Data = this.props?.preferences?.line2Data || [];

    let showDevices = this.props.deviceArr.map((a, k) => {
      let weatherButtonCss = darkmode ? "favListItemDark" : "favListItem";
      if (activeDevice == k) {
        weatherButtonCss = darkmode
          ? "favListItemDarkActive"
          : "favListItemActive";
      }

      return (
        <div key={k} className={"favListBox"}>
          <button
            className={weatherButtonCss}
            onClick={() => this.makeDeviceActive(k)}
            style={{
              borderRadius: 2,
              paddingLeft: 4,
              color: darkmode ? "#bbb" : activeDevice == k ? "#fff" : "#555",
            }}
          >
            {a.id}
          </button>
          {activeDevice == k && (
            <button onClick={() => this.removeDevice(a.id)}>
              <img
                src={darkmode ? closeXDark : closeX}
                className={"removeButtonXSettings"}
              />
            </button>
          )}
        </div>
      );
    });

    let speedArr = [1, 2, 3];
    let showSpeeds = speedArr.map((a, k) => {
      return (
        <div
          key={k}
          className={"deviceSpeedBox"}
          onClick={() => {
            this.setSpeed(a);
          }}
          style={{
            border: "1px solid #ccc",
            fontWeight: darkmode && preferences?.speed == a ? 400 : 300,
            borderColor: darkmode ? "#555" : "#ccc",
            backgroundColor:
              preferences?.speed == a
                ? darkmode
                  ? "#728cd4"
                  : "#999"
                : darkmode
                ? "#272727"
                : "#fbf7f5",
            color:
              preferences?.speed == a
                ? darkmode
                  ? "#000"
                  : "#fff"
                : darkmode
                ? "#ccc"
                : "#000",
          }}
        >
          {a}
        </div>
      );
    });

    let brightnessArr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    let showBrights = brightnessArr.map((a, k) => {
      return (
        <div
          className={"deviceSpeedBox"}
          onClick={() => {
            this.setBrightness(a);
          }}
          style={{
            border: "1px solid #ccc",
            fontWeight: darkmode && preferences?.brightness == a ? 400 : 300,
            borderColor: darkmode ? "#555" : "#ccc",
            backgroundColor:
              preferences?.brightness == a
                ? darkmode
                  ? "#728cd4"
                  : "#999"
                : darkmode
                ? "#272727"
                : "#fbf7f5",
            color:
              preferences?.brightness == a
                ? darkmode
                  ? "#000"
                  : "#fff"
                : darkmode
                ? "#ccc"
                : "#000",
          }}
        >
          {a}
        </div>
      );
    });

    let activeButtonBg = darkmode ? "#728cd4" : "#999";
    let buttonBg = darkmode ? "#272727" : "#fbf7f5";
    let activeButtonText = darkmode ? "#000" : "#fff";
    let buttonText = darkmode ? "#aaa" : "#000";
    return (
      <>
        <div
          className={"graphTextName"}
          style={{
            fontSize: 16,
            alignSelf: "flex-start",
            color: darkmode ? "#ccc" : "#444",
            marginBottom: 6,
            marginTop: 20,
            marginLeft: 2,
          }}
        >
          App Settings:
        </div>
        <div className={darkmode ? "prefBoxDark" : "prefBox"}>
          <div className={"prefRow"} style={{ marginTop: 6 }}>
            <div className={"settingsText"} style={{ marginRight: 8 }}>
              Dark Mode:
            </div>
            <Switch
              style={{ height: 18, width: 28 }}
              styles={{
                track: {
                  backgroundColor: darkmode ? "#555" : "#ccc",
                },
                trackChecked: {
                  backgroundColor: darkmode ? "#728cd4" : "#99ace0",
                },
              }}
              onChange={handleDarkModeChange}
              value={darkmode ? 1 : 0}
            />
          </div>
          <div className={"prefRow"} style={{ marginTop: 6 }}>
            <div className={"settingsText"} style={{ marginRight: 8 }}>
              Timezone:
            </div>
            <TimezoneSelect
              darkmode={darkmode}
              setTimezone={this.setTimezone}
              preferences={preferences}
            />
          </div>
        </div>
        {(isAdmin || this.state.canSleep) && (
          <Sleep uid={this.props?.uid} darkmode={darkmode} />
        )}
        <div
          className={"graphTextName"}
          style={{
            fontSize: 16,
            alignSelf: "flex-start",
            color: darkmode ? "#ccc" : "#444",
            marginBottom: 6,
            marginTop: 6,
          }}
        >
          Device Settings:
        </div>
        <div className={darkmode ? "prefBoxDark" : "prefBox"}>
          <>
            <div
              className={"settingsText"}
              style={{
                marginBottom: 10,
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "wrap",
                border: darkmode ? "0px solid #555" : "0px solid #ccc",
                borderBottomWidth: 1,
                paddingBottom: 10,
              }}
            >
              <div
                style={{
                  marginRight: 10,
                }}
              >
                Line #1:
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "wrap",
                  width: 260,
                }}
              >
                <div
                  className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                  onClick={() => {
                    this.setLine1("stocks");
                  }}
                  style={{
                    backgroundColor: line1Data?.includes("stocks")
                      ? activeButtonBg
                      : buttonBg,
                    color: line1Data?.includes("stocks")
                      ? activeButtonText
                      : buttonText,
                    fontWeight:
                      line1Data?.includes("stocks") && darkmode ? 400 : 300,
                  }}
                >
                  Stocks
                </div>
                <div
                  className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                  onClick={() => {
                    this.setLine1("crypto");
                  }}
                  style={{
                    backgroundColor: line1Data?.includes("crypto")
                      ? activeButtonBg
                      : buttonBg,
                    color: line1Data?.includes("crypto")
                      ? activeButtonText
                      : buttonText,
                    fontWeight:
                      line1Data?.includes("crypto") && darkmode ? 400 : 300,
                  }}
                >
                  Crypto
                </div>
                <div
                  className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                  onClick={() => {
                    this.setLine1("forex");
                  }}
                  style={{
                    backgroundColor: line1Data?.includes("forex")
                      ? activeButtonBg
                      : buttonBg,
                    color: line1Data?.includes("forex")
                      ? activeButtonText
                      : buttonText,
                    fontWeight:
                      line1Data?.includes("forex") && darkmode ? 400 : 300,
                  }}
                >
                  Forex
                </div>
                <div
                  className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                  onClick={() => {
                    this.setLine1("markets");
                  }}
                  style={{
                    backgroundColor: line1Data?.includes("markets")
                      ? activeButtonBg
                      : buttonBg,
                    color: line1Data?.includes("markets")
                      ? activeButtonText
                      : buttonText,
                    fontWeight:
                      line1Data?.includes("markets") && darkmode ? 400 : 300,
                  }}
                >
                  Markets
                </div>
                <div
                  className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                  onClick={() => {
                    this.setLine1("comms");
                  }}
                  style={{
                    backgroundColor: line1Data?.includes("comms")
                      ? activeButtonBg
                      : buttonBg,
                    color: line1Data?.includes("comms")
                      ? activeButtonText
                      : buttonText,
                    fontWeight:
                      line1Data?.includes("comms") && darkmode ? 400 : 300,
                  }}
                >
                  Comm.
                </div>
                <div
                  className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                  onClick={() => {
                    this.setLine1("nfl");
                  }}
                  style={{
                    backgroundColor: line1Data?.includes("nfl")
                      ? activeButtonBg
                      : buttonBg,
                    color: line1Data?.includes("nfl")
                      ? activeButtonText
                      : buttonText,
                    fontWeight:
                      line1Data?.includes("nfl") && darkmode ? 400 : 300,
                  }}
                >
                  NFL
                </div>
                <div
                  className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                  onClick={() => {
                    this.setLine1("nba");
                  }}
                  style={{
                    backgroundColor: line1Data?.includes("nba")
                      ? activeButtonBg
                      : buttonBg,
                    color: line1Data?.includes("nba")
                      ? activeButtonText
                      : buttonText,
                    fontWeight:
                      line1Data?.includes("nba") && darkmode ? 400 : 300,
                  }}
                >
                  NBA
                </div>
                <div
                  className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                  onClick={() => {
                    this.setLine1("nhl");
                  }}
                  style={{
                    backgroundColor: line1Data?.includes("nhl")
                      ? activeButtonBg
                      : buttonBg,
                    color: line1Data?.includes("nhl")
                      ? activeButtonText
                      : buttonText,
                    fontWeight:
                      line1Data?.includes("nhl") && darkmode ? 400 : 300,
                  }}
                >
                  NHL
                </div>
                <div
                  className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                  onClick={() => {
                    this.setLine1("mlb");
                  }}
                  style={{
                    backgroundColor: line1Data?.includes("mlb")
                      ? activeButtonBg
                      : buttonBg,
                    color: line1Data?.includes("mlb")
                      ? activeButtonText
                      : buttonText,
                    fontWeight:
                      line1Data?.includes("mlb") && darkmode ? 400 : 300,
                  }}
                >
                  MLB
                </div>
                {(isAdmin || isTrail) && false && (
                  <div
                    className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                    onClick={() => {
                      this.setLine1("pga");
                    }}
                    style={{
                      backgroundColor: line1Data?.includes("pga")
                        ? activeButtonBg
                        : buttonBg,
                      color: line1Data?.includes("pga")
                        ? activeButtonText
                        : buttonText,
                      fontWeight:
                        line1Data?.includes("pga") && darkmode ? 400 : 300,
                    }}
                  >
                    PGA
                  </div>
                )}
                <div
                  className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                  onClick={() => {
                    this.setLine1("ncaa");
                  }}
                  style={{
                    backgroundColor: line1Data?.includes("ncaa")
                      ? activeButtonBg
                      : buttonBg,
                    color: line1Data?.includes("ncaa")
                      ? activeButtonText
                      : buttonText,
                    fontWeight:
                      line1Data?.includes("ncaa") && darkmode ? 400 : 300,
                  }}
                >
                  NCAA
                </div>
                <div
                  className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                  onClick={() => {
                    this.setLine1("weather");
                  }}
                  style={{
                    backgroundColor: line1Data?.includes("weather")
                      ? activeButtonBg
                      : buttonBg,
                    color: line1Data?.includes("weather")
                      ? activeButtonText
                      : buttonText,
                    fontWeight:
                      line1Data?.includes("weather") && darkmode ? 400 : 300,
                  }}
                >
                  Weather
                </div>
              </div>
            </div>
            <div
              className={"settingsText"}
              style={{
                marginBottom: 10,
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "wrap",
                border: darkmode ? "0px solid #555" : "0px solid #ccc",
                borderBottomWidth: 1,
                paddingBottom: 10,
              }}
            >
              <div
                style={{
                  marginRight: 10,
                }}
              >
                Line #2:
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "wrap",
                  width: 260,
                }}
              >
                <div
                  className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                  onClick={() => {
                    this.setLine2("stocks");
                  }}
                  style={{
                    backgroundColor: line2Data?.includes("stocks")
                      ? activeButtonBg
                      : buttonBg,
                    color: line2Data?.includes("stocks")
                      ? activeButtonText
                      : buttonText,
                    fontWeight:
                      line2Data?.includes("stocks") && darkmode ? 400 : 300,
                  }}
                >
                  Stocks
                </div>
                <div
                  className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                  onClick={() => {
                    this.setLine2("crypto");
                  }}
                  style={{
                    backgroundColor: line2Data?.includes("crypto")
                      ? activeButtonBg
                      : buttonBg,
                    color: line2Data?.includes("crypto")
                      ? activeButtonText
                      : buttonText,
                    fontWeight:
                      line2Data?.includes("crypto") && darkmode ? 400 : 300,
                  }}
                >
                  Crypto
                </div>
                <div
                  className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                  onClick={() => {
                    this.setLine2("forex");
                  }}
                  style={{
                    backgroundColor: line2Data?.includes("forex")
                      ? activeButtonBg
                      : buttonBg,
                    color: line2Data?.includes("forex")
                      ? activeButtonText
                      : buttonText,
                    fontWeight:
                      line2Data?.includes("forex") && darkmode ? 400 : 300,
                  }}
                >
                  Forex
                </div>
                <div
                  className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                  onClick={() => {
                    this.setLine2("markets");
                  }}
                  style={{
                    backgroundColor: line2Data?.includes("markets")
                      ? activeButtonBg
                      : buttonBg,
                    color: line2Data?.includes("markets")
                      ? activeButtonText
                      : buttonText,
                    fontWeight:
                      line2Data?.includes("markets") && darkmode ? 400 : 300,
                  }}
                >
                  Markets
                </div>
                <div
                  className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                  onClick={() => {
                    this.setLine2("comms");
                  }}
                  style={{
                    backgroundColor: line2Data?.includes("comms")
                      ? activeButtonBg
                      : buttonBg,
                    color: line2Data?.includes("comms")
                      ? activeButtonText
                      : buttonText,
                    fontWeight:
                      line2Data?.includes("comms") && darkmode ? 400 : 300,
                  }}
                >
                  Comm.
                </div>

                <div
                  className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                  onClick={() => {
                    this.setLine2("nfl");
                  }}
                  style={{
                    backgroundColor: line2Data?.includes("nfl")
                      ? activeButtonBg
                      : buttonBg,
                    color: line2Data?.includes("nfl")
                      ? activeButtonText
                      : buttonText,
                    fontWeight:
                      line2Data?.includes("nfl") && darkmode ? 400 : 300,
                  }}
                >
                  NFL
                </div>
                <div
                  className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                  onClick={() => {
                    this.setLine2("nba");
                  }}
                  style={{
                    backgroundColor: line2Data?.includes("nba")
                      ? activeButtonBg
                      : buttonBg,
                    color: line2Data?.includes("nba")
                      ? activeButtonText
                      : buttonText,
                    fontWeight:
                      line2Data?.includes("nba") && darkmode ? 400 : 300,
                  }}
                >
                  NBA
                </div>
                <div
                  className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                  onClick={() => {
                    this.setLine2("nhl");
                  }}
                  style={{
                    backgroundColor: line2Data?.includes("nhl")
                      ? activeButtonBg
                      : buttonBg,
                    color: line2Data?.includes("nhl")
                      ? activeButtonText
                      : buttonText,
                    fontWeight:
                      line2Data?.includes("nhl") && darkmode ? 400 : 300,
                  }}
                >
                  NHL
                </div>
                <div
                  className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                  onClick={() => {
                    this.setLine2("mlb");
                  }}
                  style={{
                    backgroundColor: line2Data?.includes("mlb")
                      ? activeButtonBg
                      : buttonBg,
                    color: line2Data?.includes("mlb")
                      ? activeButtonText
                      : buttonText,
                    fontWeight:
                      line2Data?.includes("mlb") && darkmode ? 400 : 300,
                  }}
                >
                  MLB
                </div>
                {(isAdmin || isTrail) && false && (
                  <div
                    className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                    onClick={() => {
                      this.setLine2("pga");
                    }}
                    style={{
                      backgroundColor: line2Data?.includes("pga")
                        ? activeButtonBg
                        : buttonBg,
                      color: line2Data?.includes("pga")
                        ? activeButtonText
                        : buttonText,
                      fontWeight:
                        line2Data?.includes("pga") && darkmode ? 400 : 300,
                    }}
                  >
                    PGA
                  </div>
                )}

                <div
                  className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                  onClick={() => {
                    this.setLine2("ncaa");
                  }}
                  style={{
                    backgroundColor: line2Data?.includes("ncaa")
                      ? activeButtonBg
                      : buttonBg,
                    color: line2Data?.includes("ncaa")
                      ? activeButtonText
                      : buttonText,
                    fontWeight:
                      line2Data?.includes("ncaa") && darkmode ? 400 : 300,
                  }}
                >
                  NCAA
                </div>
                <div
                  className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                  onClick={() => {
                    this.setLine2("weather");
                  }}
                  style={{
                    backgroundColor: line2Data?.includes("weather")
                      ? activeButtonBg
                      : buttonBg,
                    color: line2Data?.includes("weather")
                      ? activeButtonText
                      : buttonText,
                    fontWeight:
                      line2Data?.includes("weather") && darkmode ? 400 : 300,
                  }}
                >
                  Weather
                </div>
              </div>
            </div>
            {false && (
              <div
                onClick={() => {
                  this.enableSportsOverride(!preferences?.override);
                }}
                className={"settingsText"}
                style={{
                  marginBottom: 10,
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 4,
                  cursor: "pointer",
                }}
              >
                <div
                  className={"deviceSpeedBox"}
                  style={{
                    backgroundColor: preferences?.override
                      ? darkmode
                        ? "#5e7cce"
                        : "#999"
                      : darkmode
                      ? "#272727"
                      : "#fbf7f5",
                    color: preferences?.multiLine ? "#fff" : "#555",
                    marginRight: 6,
                    border: "1px solid #555",
                  }}
                >
                  {preferences?.override ? "✓" : ""}
                </div>
                <div style={{ marginRight: 10 }}>Enable Sports Override</div>
              </div>
            )}
          </>
          <div
            className={"settingsText"}
            style={{
              marginTop: 4,
              marginBottom: 10,
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: 10, marginTop: -9 }}>Scroll Speed:</div>
            <SpeedSlider
              darkmode={darkmode}
              setValue={this.setSpeed}
              value={preferences?.speed}
            />
          </div>
          <div
            className={"settingsText"}
            style={{
              marginBottom: 6,
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              width: 300,
            }}
          >
            <div style={{ marginRight: 10, marginTop: 6 }}>Brightness:</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginLeft: 8,
              }}
            >
              <BrightnessSlider
                darkmode={darkmode}
                setValue={this.setBrightness}
                value={preferences?.brightness}
              />
            </div>
          </div>
        </div>
        <div
          className={"graphTextName"}
          style={{
            fontSize: 16,
            alignSelf: "flex-start",
            color: darkmode ? "#ccc" : "#444",
            marginBottom: 6,
            marginTop: 6,
          }}
        >
          Custom:
        </div>
        <div className={darkmode ? "prefBoxDark" : "prefBox"}>
          <div
            style={{
              alignSelf: "stretch",
              marginBottom: 4,
              width: 330,
            }}
          >
            <div className={darkmode ? "deviceidtextDark" : "deviceidtext"}>
              Send Message to Quotron:
            </div>
            <form
              onSubmit={(e) => this.onTextSubmit(e)}
              style={{
                display: "flex",
                alignSelf: "stretch",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <input
                type="text"
                className={
                  isMobile
                    ? darkmode
                      ? "settingsDeviceAddMobileDark"
                      : "settingsDeviceAddMobile"
                    : darkmode
                    ? "settingsDeviceAddDark"
                    : "settingsDeviceAdd"
                }
                style={{
                  backgroundColor: deviceIDError
                    ? "#ff7f7f"
                    : darkmode
                    ? "#272727"
                    : "#FFF",
                  color: darkmode ? "#ccc" : "#000",
                }}
                style={{ minWidth: 288 }}
                value={textString}
                onChange={this.onTextChange}
              />
              <div
                onClick={(e) => this.onTextSubmit(e)}
                className={"searchSubmitButtonBox"}
                style={{ marginTop: -10 }}
              >
                <input
                  type="submit"
                  className={
                    darkmode ? "searchSubmitButtonDark" : "searchSubmitButton"
                  }
                  value=""
                  style={{ height: 38, width: 38 }}
                />
                <div
                  className={darkmode ? "submitPlusFontDark" : "submitPlusFont"}
                  style={{ left: 9, top: 9 }}
                >
                  +
                </div>
              </div>
            </form>
            <div
              className={"profileTextSub"}
              style={{ width: 300 }}
            >{`*custom message will run 3 times`}</div>

            {false && (
              <div className={"flexRow"} style={{ alignItems: "center" }}>
                <div
                  className={darkmode ? "deviceidtextDark" : "deviceidtext"}
                  style={{ marginRight: 6 }}
                >
                  Always on :
                </div>
                <Switch
                  style={{ height: 18, width: 28 }}
                  styles={{
                    track: {
                      backgroundColor: darkmode ? "#555" : "#ccc",
                    },
                    trackChecked: {
                      backgroundColor: darkmode ? "#728cd4" : "#99ace0",
                    },
                  }}
                  onChange={() => {}}
                  value={true ? 1 : 0}
                />
              </div>
            )}
            {false && (
              <div className={"flexRow"} style={{ alignItems: "center" }}>
                <div
                  className={darkmode ? "deviceidtextDark" : "deviceidtext"}
                  style={{ marginRight: 6 }}
                >
                  {`Rotate within stream :`}
                </div>
                <Switch
                  style={{ height: 18, width: 28 }}
                  styles={{
                    track: {
                      backgroundColor: darkmode ? "#555" : "#ccc",
                    },
                    trackChecked: {
                      backgroundColor: darkmode ? "#728cd4" : "#99ace0",
                    },
                  }}
                  onChange={() => {}}
                  value={true ? 1 : 0}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className={"graphTextName"}
          style={{
            fontSize: 16,
            alignSelf: "flex-start",
            color: darkmode ? "#ccc" : "#444",
            marginBottom: 6,
            marginTop: 6,
          }}
        >
          Devices:
        </div>
        <div className={darkmode ? "prefBoxDark" : "prefBox"}>
          <div style={{ alignSelf: "stretch", marginBottom: 4 }}>
            <div className={darkmode ? "deviceidtextDark" : "deviceidtext"}>
              Add Device:
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <input
                type="text"
                className={
                  isMobile
                    ? darkmode
                      ? "settingsDeviceAddMobileDark"
                      : "settingsDeviceAddMobile"
                    : darkmode
                    ? "settingsDeviceAddDark"
                    : "settingsDeviceAdd"
                }
                style={{
                  backgroundColor: deviceIDError
                    ? "#ff7f7f"
                    : darkmode
                    ? "#272727"
                    : "#FFF",
                  color: deviceIDError ? "#000" : darkmode ? "#ccc" : "#000",
                }}
                value={deviceID}
                onChange={this.onDeviceIdInput}
                placeholder="123-456-789"
              />

              <div
                onClick={() => this.registerDevice()}
                className={"searchSubmitButtonBox"}
              >
                <input
                  type="submit"
                  className={
                    darkmode ? "searchSubmitButtonDark" : "searchSubmitButton"
                  }
                  value=""
                  style={{ height: 38, width: 38 }}
                />
                <div
                  className={darkmode ? "submitPlusFontDark" : "submitPlusFont"}
                  style={{ left: 9, top: 9 }}
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div style={{ alignSelf: "stretch", marginBottom: 0 }}>
            <div className={"settingsText"}>Devices List:</div>
            <div className={"favList"} style={{ marginTop: 2 }}>
              {showDevices}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Settings;
